import {
  ALSTROEMERIA_HUE,
  BLUE_HUE,
  COMETIK_HUE,
  generateScale,
  GREEN_HUE,
  HOKAGO_HUE,
  ILLUMINATE_HUE,
  LANTICA_HUE,
  NOCTCHILL_HUE,
  RED_HUE,
  SHHIS_HUE,
  SHINY_HUE,
  STRAYLIGHT_HUE,
} from '#/alf/util/colorGeneration'

export const scale = generateScale(6, 100)
// dim shifted 6% lighter
export const dimScale = generateScale(12, 100)

export const color = {
  trueBlack: '#000000',

  temp_purple: 'rgb(105 0 255)',
  temp_purple_dark: 'rgb(83 0 202)',

  gray_0: `hsl(${BLUE_HUE}, 20%, ${scale[14]}%)`,
  gray_25: `hsl(${BLUE_HUE}, 20%, ${scale[13]}%)`,
  gray_50: `hsl(${BLUE_HUE}, 20%, ${scale[12]}%)`,
  gray_100: `hsl(${BLUE_HUE}, 20%, ${scale[11]}%)`,
  gray_200: `hsl(${BLUE_HUE}, 20%, ${scale[10]}%)`,
  gray_300: `hsl(${BLUE_HUE}, 20%, ${scale[9]}%)`,
  gray_400: `hsl(${BLUE_HUE}, 20%, ${scale[8]}%)`,
  gray_500: `hsl(${BLUE_HUE}, 20%, ${scale[7]}%)`,
  gray_600: `hsl(${BLUE_HUE}, 24%, ${scale[6]}%)`,
  gray_700: `hsl(${BLUE_HUE}, 24%, ${scale[5]}%)`,
  gray_800: `hsl(${BLUE_HUE}, 28%, ${scale[4]}%)`,
  gray_900: `hsl(${BLUE_HUE}, 28%, ${scale[3]}%)`,
  gray_950: `hsl(${BLUE_HUE}, 28%, ${scale[2]}%)`,
  gray_975: `hsl(${BLUE_HUE}, 28%, ${scale[1]}%)`,
  gray_1000: `hsl(${BLUE_HUE}, 28%, ${scale[0]}%)`,

  blue_25: `hsl(${BLUE_HUE}, 99%, 97%)`,
  blue_50: `hsl(${BLUE_HUE}, 99%, 95%)`,
  blue_100: `hsl(${BLUE_HUE}, 99%, 90%)`,
  blue_200: `hsl(${BLUE_HUE}, 99%, 80%)`,
  blue_300: `hsl(${BLUE_HUE}, 99%, 70%)`,
  blue_400: `hsl(${BLUE_HUE}, 99%, 60%)`,
  blue_500: `hsl(${BLUE_HUE}, 99%, 53%)`,
  blue_600: `hsl(${BLUE_HUE}, 99%, 42%)`,
  blue_700: `hsl(${BLUE_HUE}, 99%, 34%)`,
  blue_800: `hsl(${BLUE_HUE}, 99%, 26%)`,
  blue_900: `hsl(${BLUE_HUE}, 99%, 18%)`,
  blue_950: `hsl(${BLUE_HUE}, 99%, 10%)`,
  blue_975: `hsl(${BLUE_HUE}, 99%, 7%)`,

  green_25: `hsl(${GREEN_HUE}, 82%, 97%)`,
  green_50: `hsl(${GREEN_HUE}, 82%, 95%)`,
  green_100: `hsl(${GREEN_HUE}, 82%, 90%)`,
  green_200: `hsl(${GREEN_HUE}, 82%, 80%)`,
  green_300: `hsl(${GREEN_HUE}, 82%, 70%)`,
  green_400: `hsl(${GREEN_HUE}, 82%, 60%)`,
  green_500: `hsl(${GREEN_HUE}, 82%, 50%)`,
  green_600: `hsl(${GREEN_HUE}, 82%, 42%)`,
  green_700: `hsl(${GREEN_HUE}, 82%, 34%)`,
  green_800: `hsl(${GREEN_HUE}, 82%, 26%)`,
  green_900: `hsl(${GREEN_HUE}, 82%, 18%)`,
  green_950: `hsl(${GREEN_HUE}, 82%, 10%)`,
  green_975: `hsl(${GREEN_HUE}, 82%, 7%)`,

  red_25: `hsl(${RED_HUE}, 91%, 97%)`,
  red_50: `hsl(${RED_HUE}, 91%, 95%)`,
  red_100: `hsl(${RED_HUE}, 91%, 90%)`,
  red_200: `hsl(${RED_HUE}, 91%, 80%)`,
  red_300: `hsl(${RED_HUE}, 91%, 70%)`,
  red_400: `hsl(${RED_HUE}, 91%, 60%)`,
  red_500: `hsl(${RED_HUE}, 91%, 50%)`,
  red_600: `hsl(${RED_HUE}, 91%, 42%)`,
  red_700: `hsl(${RED_HUE}, 91%, 34%)`,
  red_800: `hsl(${RED_HUE}, 91%, 26%)`,
  red_900: `hsl(${RED_HUE}, 91%, 18%)`,
  red_950: `hsl(${RED_HUE}, 91%, 10%)`,
  red_975: `hsl(${RED_HUE}, 91%, 7%)`,

  // shiny #8dbbff
  shiny_0: `hsl(${SHINY_HUE}, 100%, 100%)`,
  shiny_25: `hsl(${SHINY_HUE}, 100%, 97%)`,
  shiny_50: `hsl(${SHINY_HUE}, 100%, 95%)`,
  shiny_100: `hsl(${SHINY_HUE}, 100%, 90%)`,
  shiny_200: `hsl(${SHINY_HUE}, 100%, 80%)`,
  shiny_300: `hsl(${SHINY_HUE}, 100%, 70%)`,
  shiny_400: `hsl(${SHINY_HUE}, 100%, 60%)`,
  shiny_500: `hsl(${SHINY_HUE}, 100%, 50%)`,
  shiny_600: `hsl(${SHINY_HUE}, 100%, 42%)`,
  shiny_700: `hsl(${SHINY_HUE}, 100%, 34%)`,
  shiny_800: `hsl(${SHINY_HUE}, 100%, 26%)`,
  shiny_900: `hsl(${SHINY_HUE}, 100%, 18%)`,
  shiny_950: `hsl(${SHINY_HUE}, 100%, 10%)`,
  shiny_975: `hsl(${SHINY_HUE}, 100%, 7%)`,
  shiny_1000: `hsl(${SHINY_HUE}, 100%, 4%)`,

  // illuminate #fff68d
  illuminate_0: `hsl(${ILLUMINATE_HUE}, 100%, 100%)`,
  illuminate_25: `hsl(${ILLUMINATE_HUE}, 100%, 97%)`,
  illuminate_50: `hsl(${ILLUMINATE_HUE}, 100%, 95%)`,
  illuminate_100: `hsl(${ILLUMINATE_HUE}, 100%, 90%)`,
  illuminate_200: `hsl(${ILLUMINATE_HUE}, 100%, 80%)`,
  illuminate_300: `hsl(${ILLUMINATE_HUE}, 100%, 70%)`,
  illuminate_400: `hsl(${ILLUMINATE_HUE}, 100%, 60%)`,
  illuminate_500: `hsl(${ILLUMINATE_HUE}, 100%, 50%)`,
  illuminate_600: `hsl(${ILLUMINATE_HUE}, 100%, 42%)`,
  illuminate_700: `hsl(${ILLUMINATE_HUE}, 100%, 34%)`,
  illuminate_800: `hsl(${ILLUMINATE_HUE}, 100%, 26%)`,
  illuminate_900: `hsl(${ILLUMINATE_HUE}, 100%, 18%)`,
  illuminate_950: `hsl(${ILLUMINATE_HUE}, 100%, 10%)`,
  illuminate_975: `hsl(${ILLUMINATE_HUE}, 100%, 7%)`,
  illuminate_1000: `hsl(${ILLUMINATE_HUE}, 100%, 4%)`,

  // lantica #853998
  lantica_0: `hsl(${LANTICA_HUE}, 100%, 100%)`,
  lantica_25: `hsl(${LANTICA_HUE}, 100%, 97%)`,
  lantica_50: `hsl(${LANTICA_HUE}, 100%, 95%)`,
  lantica_100: `hsl(${LANTICA_HUE}, 100%, 90%)`,
  lantica_200: `hsl(${LANTICA_HUE}, 100%, 80%)`,
  lantica_300: `hsl(${LANTICA_HUE}, 100%, 70%)`,
  lantica_400: `hsl(${LANTICA_HUE}, 100%, 60%)`,
  lantica_500: `hsl(${LANTICA_HUE}, 100%, 50%)`,
  lantica_600: `hsl(${LANTICA_HUE}, 100%, 42%)`,
  lantica_700: `hsl(${LANTICA_HUE}, 100%, 34%)`,
  lantica_800: `hsl(${LANTICA_HUE}, 100%, 26%)`,
  lantica_900: `hsl(${LANTICA_HUE}, 100%, 18%)`,
  lantica_950: `hsl(${LANTICA_HUE}, 100%, 10%)`,
  lantica_975: `hsl(${LANTICA_HUE}, 100%, 7%)`,
  lantica_1000: `hsl(${LANTICA_HUE}, 100%, 4%)`,

  // hokago #fa8333
  hokago_0: `hsl(${HOKAGO_HUE}, 100%, 100%)`,
  hokago_25: `hsl(${HOKAGO_HUE}, 100%, 97%)`,
  hokago_50: `hsl(${HOKAGO_HUE}, 100%, 95%)`,
  hokago_100: `hsl(${HOKAGO_HUE}, 100%, 90%)`,
  hokago_200: `hsl(${HOKAGO_HUE}, 100%, 80%)`,
  hokago_300: `hsl(${HOKAGO_HUE}, 100%, 70%)`,
  hokago_400: `hsl(${HOKAGO_HUE}, 100%, 60%)`,
  hokago_500: `hsl(${HOKAGO_HUE}, 100%, 50%)`,
  hokago_600: `hsl(${HOKAGO_HUE}, 100%, 42%)`,
  hokago_700: `hsl(${HOKAGO_HUE}, 100%, 34%)`,
  hokago_800: `hsl(${HOKAGO_HUE}, 100%, 26%)`,
  hokago_900: `hsl(${HOKAGO_HUE}, 100%, 18%)`,
  hokago_950: `hsl(${HOKAGO_HUE}, 100%, 10%)`,
  hokago_975: `hsl(${HOKAGO_HUE}, 100%, 7%)`,
  hokago_1000: `hsl(${HOKAGO_HUE}, 100%, 4%)`,

  // alstroemeria #ff699e
  alstroemeria_0: `hsl(${ALSTROEMERIA_HUE}, 100%, 100%)`,
  alstroemeria_25: `hsl(${ALSTROEMERIA_HUE}, 100%, 97%)`,
  alstroemeria_50: `hsl(${ALSTROEMERIA_HUE}, 100%, 95%)`,
  alstroemeria_100: `hsl(${ALSTROEMERIA_HUE}, 100%, 90%)`,
  alstroemeria_200: `hsl(${ALSTROEMERIA_HUE}, 100%, 80%)`,
  alstroemeria_300: `hsl(${ALSTROEMERIA_HUE}, 100%, 70%)`,
  alstroemeria_400: `hsl(${ALSTROEMERIA_HUE}, 100%, 60%)`,
  alstroemeria_500: `hsl(${ALSTROEMERIA_HUE}, 100%, 50%)`,
  alstroemeria_600: `hsl(${ALSTROEMERIA_HUE}, 100%, 42%)`,
  alstroemeria_700: `hsl(${ALSTROEMERIA_HUE}, 100%, 34%)`,
  alstroemeria_800: `hsl(${ALSTROEMERIA_HUE}, 100%, 26%)`,
  alstroemeria_900: `hsl(${ALSTROEMERIA_HUE}, 100%, 18%)`,
  alstroemeria_950: `hsl(${ALSTROEMERIA_HUE}, 100%, 10%)`,
  alstroemeria_975: `hsl(${ALSTROEMERIA_HUE}, 100%, 7%)`,
  alstroemeria_1000: `hsl(${ALSTROEMERIA_HUE}, 100%, 4%)`,

  // straylight #af011c
  straylight_0: `hsl(${STRAYLIGHT_HUE}, 100%, 100%)`,
  straylight_25: `hsl(${STRAYLIGHT_HUE}, 100%, 97%)`,
  straylight_50: `hsl(${STRAYLIGHT_HUE}, 100%, 95%)`,
  straylight_100: `hsl(${STRAYLIGHT_HUE}, 100%, 90%)`,
  straylight_200: `hsl(${STRAYLIGHT_HUE}, 100%, 80%)`,
  straylight_300: `hsl(${STRAYLIGHT_HUE}, 100%, 70%)`,
  straylight_400: `hsl(${STRAYLIGHT_HUE}, 100%, 60%)`,
  straylight_500: `hsl(${STRAYLIGHT_HUE}, 100%, 50%)`,
  straylight_600: `hsl(${STRAYLIGHT_HUE}, 100%, 42%)`,
  straylight_700: `hsl(${STRAYLIGHT_HUE}, 100%, 34%)`,
  straylight_800: `hsl(${STRAYLIGHT_HUE}, 100%, 26%)`,
  straylight_900: `hsl(${STRAYLIGHT_HUE}, 100%, 18%)`,
  straylight_950: `hsl(${STRAYLIGHT_HUE}, 100%, 10%)`,
  straylight_975: `hsl(${STRAYLIGHT_HUE}, 100%, 7%)`,
  straylight_1000: `hsl(${STRAYLIGHT_HUE}, 100%, 4%)`,

  // noctchill #384d98
  noctchill_0: `hsl(${NOCTCHILL_HUE}, 100%, 100%)`,
  noctchill_25: `hsl(${NOCTCHILL_HUE}, 100%, 97%)`,
  noctchill_50: `hsl(${NOCTCHILL_HUE}, 100%, 95%)`,
  noctchill_100: `hsl(${NOCTCHILL_HUE}, 100%, 90%)`,
  noctchill_200: `hsl(${NOCTCHILL_HUE}, 100%, 80%)`,
  noctchill_300: `hsl(${NOCTCHILL_HUE}, 100%, 70%)`,
  noctchill_400: `hsl(${NOCTCHILL_HUE}, 100%, 60%)`,
  noctchill_500: `hsl(${NOCTCHILL_HUE}, 100%, 50%)`,
  noctchill_600: `hsl(${NOCTCHILL_HUE}, 100%, 42%)`,
  noctchill_700: `hsl(${NOCTCHILL_HUE}, 100%, 34%)`,
  noctchill_800: `hsl(${NOCTCHILL_HUE}, 100%, 26%)`,
  noctchill_900: `hsl(${NOCTCHILL_HUE}, 100%, 18%)`,
  noctchill_950: `hsl(${NOCTCHILL_HUE}, 100%, 10%)`,
  noctchill_975: `hsl(${NOCTCHILL_HUE}, 100%, 7%)`,
  noctchill_1000: `hsl(${NOCTCHILL_HUE}, 100%, 4%)`,

  // shhis #008e74
  shhis_0: `hsl(${SHHIS_HUE}, 100%, 100%)`,
  shhis_25: `hsl(${SHHIS_HUE}, 100%, 97%)`,
  shhis_50: `hsl(${SHHIS_HUE}, 100%, 95%)`,
  shhis_100: `hsl(${SHHIS_HUE}, 100%, 90%)`,
  shhis_200: `hsl(${SHHIS_HUE}, 100%, 80%)`,
  shhis_300: `hsl(${SHHIS_HUE}, 100%, 70%)`,
  shhis_400: `hsl(${SHHIS_HUE}, 100%, 60%)`,
  shhis_500: `hsl(${SHHIS_HUE}, 100%, 50%)`,
  shhis_600: `hsl(${SHHIS_HUE}, 100%, 42%)`,
  shhis_700: `hsl(${SHHIS_HUE}, 100%, 34%)`,
  shhis_800: `hsl(${SHHIS_HUE}, 100%, 26%)`,
  shhis_900: `hsl(${SHHIS_HUE}, 100%, 18%)`,
  shhis_950: `hsl(${SHHIS_HUE}, 100%, 10%)`,
  shhis_975: `hsl(${SHHIS_HUE}, 100%, 7%)`,
  shhis_1000: `hsl(${SHHIS_HUE}, 100%, 4%)`,

  // cometik #333333
  cometik_0: `hsl(${COMETIK_HUE}, 0%, 100%)`,
  cometik_25: `hsl(${COMETIK_HUE}, 0%, 97%)`,
  cometik_50: `hsl(${COMETIK_HUE}, 0%, 95%)`,
  cometik_100: `hsl(${COMETIK_HUE}, 0%, 90%)`,
  cometik_200: `hsl(${COMETIK_HUE}, 0%, 80%)`,
  cometik_300: `hsl(${COMETIK_HUE}, 0%, 70%)`,
  cometik_400: `hsl(${COMETIK_HUE}, 0%, 60%)`,
  cometik_500: `hsl(${COMETIK_HUE}, 0%, 50%)`,
  cometik_600: `hsl(${COMETIK_HUE}, 0%, 42%)`,
  cometik_700: `hsl(${COMETIK_HUE}, 0%, 34%)`,
  cometik_800: `hsl(${COMETIK_HUE}, 0%, 26%)`,
  cometik_900: `hsl(${COMETIK_HUE}, 0%, 18%)`,
  cometik_950: `hsl(${COMETIK_HUE}, 0%, 10%)`,
  cometik_975: `hsl(${COMETIK_HUE}, 0%, 7%)`,
  cometik_1000: `hsl(${COMETIK_HUE}, 0%, 4%)`,
} as const

export const space = {
  _2xs: 2,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 20,
  _2xl: 24,
  _3xl: 28,
  _4xl: 32,
  _5xl: 40,
} as const

export const fontSize = {
  _2xs: 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  _2xl: 22,
  _3xl: 26,
  _4xl: 32,
  _5xl: 40,
} as const

export const lineHeight = {
  none: 1,
  normal: 1.5,
  relaxed: 1.625,
} as const

export const borderRadius = {
  _2xs: 2,
  xs: 4,
  sm: 8,
  md: 12,
  full: 999,
} as const

export const fontWeight = {
  normal: '400',
  semibold: '500',
  bold: '600',
  heavy: '700',
} as const

export const gradients = {
  sky: {
    values: [
      [0, '#0A7AFF'],
      [1, '#59B9FF'],
    ],
    hover_value: '#0A7AFF',
  },
  midnight: {
    values: [
      [0, '#022C5E'],
      [1, '#4079BC'],
    ],
    hover_value: '#022C5E',
  },
  sunrise: {
    values: [
      [0, '#4E90AE'],
      [0.4, '#AEA3AB'],
      [0.8, '#E6A98F'],
      [1, '#F3A84C'],
    ],
    hover_value: '#AEA3AB',
  },
  sunset: {
    values: [
      [0, '#6772AF'],
      [0.6, '#B88BB6'],
      [1, '#FFA6AC'],
    ],
    hover_value: '#B88BB6',
  },
  summer: {
    values: [
      [0, '#FF6A56'],
      [0.3, '#FF9156'],
      [1, '#FFDD87'],
    ],
    hover_value: '#FF9156',
  },
  nordic: {
    values: [
      [0, '#083367'],
      [1, '#9EE8C1'],
    ],
    hover_value: '#3A7085',
  },
  bonfire: {
    values: [
      [0, '#203E4E'],
      [0.4, '#755B62'],
      [0.8, '#CD7765'],
      [1, '#EF956E'],
    ],
    hover_value: '#755B62',
  },
} as const
