import {atoms} from '#/alf/atoms'
import {Palette, Theme, ThemeName} from '#/alf/types'
import {
  defaultScale,
  dimScale,
  ALSTROEMERIA_HUE,
  BLUE_HUE,
  COMETIK_HUE,
  GREEN_HUE,
  HOKAGO_HUE,
  ILLUMINATE_HUE,
  LANTICA_HUE,
  NOCTCHILL_HUE,
  RED_HUE,
  SHHIS_HUE,
  SHINY_HUE,
  STRAYLIGHT_HUE,
} from '#/alf/util/colorGeneration'

const themes = createThemes({
  hues: {
    primary: BLUE_HUE,
    negative: RED_HUE,
    positive: GREEN_HUE,
  },
})

/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const lightPalette = themes.lightPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const darkPalette = themes.darkPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const dimPalette = themes.dimPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const shinyPalette = themes.shinyPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const illuminatePalette = themes.illuminatePalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const lanticaPalette = themes.lanticaPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const hokagoPalette = themes.hokagoPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const alstroemeriaPalette = themes.alstroemeriaPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const straylightPalette = themes.straylightPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const noctchillPalette = themes.noctchillPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const shhisPalette = themes.shhisPalette
/**
 * @deprecated use ALF and access palette from `useTheme()`
 */
export const cometikPalette = themes.cometikPalette
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const light = themes.light
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const dark = themes.dark
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const dim = themes.dim
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const shiny = themes.shiny
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const illuminate = themes.illuminate
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const lantica = themes.lantica
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const hokago = themes.hokago
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const alstroemeria = themes.alstroemeria
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const straylight = themes.straylight
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const noctchill = themes.noctchill
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const shhis = themes.shhis
/**
 * @deprecated use ALF and access theme from `useTheme()`
 */
export const cometik = themes.cometik

export const defaultTheme = themes.light

export function createThemes({
  hues,
}: {
  hues: {
    primary: number
    negative: number
    positive: number
  }
}): {
  lightPalette: Palette
  darkPalette: Palette
  dimPalette: Palette
  shinyPalette: Palette
  illuminatePalette: Palette
  lanticaPalette: Palette
  hokagoPalette: Palette
  alstroemeriaPalette: Palette
  straylightPalette: Palette
  noctchillPalette: Palette
  shhisPalette: Palette
  cometikPalette: Palette
  light: Theme
  dark: Theme
  dim: Theme
  shiny: Theme
  illuminate: Theme
  lantica: Theme
  hokago: Theme
  alstroemeria: Theme
  straylight: Theme
  noctchill: Theme
  shhis: Theme
  cometik: Theme
} {
  const color = {
    trueBlack: '#000000',

    gray_0: `hsl(${hues.primary}, 20%, ${defaultScale[14]}%)`,
    gray_25: `hsl(${hues.primary}, 20%, ${defaultScale[13]}%)`,
    gray_50: `hsl(${hues.primary}, 20%, ${defaultScale[12]}%)`,
    gray_100: `hsl(${hues.primary}, 20%, ${defaultScale[11]}%)`,
    gray_200: `hsl(${hues.primary}, 20%, ${defaultScale[10]}%)`,
    gray_300: `hsl(${hues.primary}, 20%, ${defaultScale[9]}%)`,
    gray_400: `hsl(${hues.primary}, 20%, ${defaultScale[8]}%)`,
    gray_500: `hsl(${hues.primary}, 20%, ${defaultScale[7]}%)`,
    gray_600: `hsl(${hues.primary}, 24%, ${defaultScale[6]}%)`,
    gray_700: `hsl(${hues.primary}, 24%, ${defaultScale[5]}%)`,
    gray_800: `hsl(${hues.primary}, 28%, ${defaultScale[4]}%)`,
    gray_900: `hsl(${hues.primary}, 28%, ${defaultScale[3]}%)`,
    gray_950: `hsl(${hues.primary}, 28%, ${defaultScale[2]}%)`,
    gray_975: `hsl(${hues.primary}, 28%, ${defaultScale[1]}%)`,
    gray_1000: `hsl(${hues.primary}, 28%, ${defaultScale[0]}%)`,

    primary_25: `hsl(${hues.primary}, 99%, 97%)`,
    primary_50: `hsl(${hues.primary}, 99%, 95%)`,
    primary_100: `hsl(${hues.primary}, 99%, 90%)`,
    primary_200: `hsl(${hues.primary}, 99%, 80%)`,
    primary_300: `hsl(${hues.primary}, 99%, 70%)`,
    primary_400: `hsl(${hues.primary}, 99%, 60%)`,
    primary_500: `hsl(${hues.primary}, 99%, 53%)`,
    primary_600: `hsl(${hues.primary}, 99%, 42%)`,
    primary_700: `hsl(${hues.primary}, 99%, 34%)`,
    primary_800: `hsl(${hues.primary}, 99%, 26%)`,
    primary_900: `hsl(${hues.primary}, 99%, 18%)`,
    primary_950: `hsl(${hues.primary}, 99%, 10%)`,
    primary_975: `hsl(${hues.primary}, 99%, 7%)`,

    green_25: `hsl(${hues.positive}, 82%, 97%)`,
    green_50: `hsl(${hues.positive}, 82%, 95%)`,
    green_100: `hsl(${hues.positive}, 82%, 90%)`,
    green_200: `hsl(${hues.positive}, 82%, 80%)`,
    green_300: `hsl(${hues.positive}, 82%, 70%)`,
    green_400: `hsl(${hues.positive}, 82%, 60%)`,
    green_500: `hsl(${hues.positive}, 82%, 50%)`,
    green_600: `hsl(${hues.positive}, 82%, 42%)`,
    green_700: `hsl(${hues.positive}, 82%, 34%)`,
    green_800: `hsl(${hues.positive}, 82%, 26%)`,
    green_900: `hsl(${hues.positive}, 82%, 18%)`,
    green_950: `hsl(${hues.positive}, 82%, 10%)`,
    green_975: `hsl(${hues.positive}, 82%, 7%)`,

    red_25: `hsl(${hues.negative}, 91%, 97%)`,
    red_50: `hsl(${hues.negative}, 91%, 95%)`,
    red_100: `hsl(${hues.negative}, 91%, 90%)`,
    red_200: `hsl(${hues.negative}, 91%, 80%)`,
    red_300: `hsl(${hues.negative}, 91%, 70%)`,
    red_400: `hsl(${hues.negative}, 91%, 60%)`,
    red_500: `hsl(${hues.negative}, 91%, 50%)`,
    red_600: `hsl(${hues.negative}, 91%, 42%)`,
    red_700: `hsl(${hues.negative}, 91%, 34%)`,
    red_800: `hsl(${hues.negative}, 91%, 26%)`,
    red_900: `hsl(${hues.negative}, 91%, 18%)`,
    red_950: `hsl(${hues.negative}, 91%, 10%)`,
    red_975: `hsl(${hues.negative}, 91%, 7%)`,

    // shiny #8dbbff
    shiny_0: `hsl(${SHINY_HUE}, 100%, 100%)`,
    shiny_25: `hsl(${SHINY_HUE}, 100%, 97%)`,
    shiny_50: `hsl(${SHINY_HUE}, 100%, 95%)`,
    shiny_100: `hsl(${SHINY_HUE}, 100%, 90%)`,
    shiny_200: `hsl(${SHINY_HUE}, 100%, 80%)`,
    shiny_300: `hsl(${SHINY_HUE}, 100%, 70%)`,
    shiny_400: `hsl(${SHINY_HUE}, 100%, 60%)`,
    shiny_500: `hsl(${SHINY_HUE}, 100%, 50%)`,
    shiny_600: `hsl(${SHINY_HUE}, 100%, 42%)`,
    shiny_700: `hsl(${SHINY_HUE}, 100%, 34%)`,
    shiny_800: `hsl(${SHINY_HUE}, 100%, 26%)`,
    shiny_900: `hsl(${SHINY_HUE}, 100%, 18%)`,
    shiny_950: `hsl(${SHINY_HUE}, 100%, 10%)`,
    shiny_975: `hsl(${SHINY_HUE}, 100%, 7%)`,
    shiny_1000: `hsl(${SHINY_HUE}, 100%, 4%)`,

    // illuminate #fff68d
    illuminate_0: `hsl(${ILLUMINATE_HUE}, 100%, 100%)`,
    illuminate_25: `hsl(${ILLUMINATE_HUE}, 100%, 97%)`,
    illuminate_50: `hsl(${ILLUMINATE_HUE}, 100%, 95%)`,
    illuminate_100: `hsl(${ILLUMINATE_HUE}, 100%, 90%)`,
    illuminate_200: `hsl(${ILLUMINATE_HUE}, 100%, 80%)`,
    illuminate_300: `hsl(${ILLUMINATE_HUE}, 100%, 70%)`,
    illuminate_400: `hsl(${ILLUMINATE_HUE}, 100%, 60%)`,
    illuminate_500: `hsl(${ILLUMINATE_HUE}, 100%, 50%)`,
    illuminate_600: `hsl(${ILLUMINATE_HUE}, 100%, 42%)`,
    illuminate_700: `hsl(${ILLUMINATE_HUE}, 100%, 34%)`,
    illuminate_800: `hsl(${ILLUMINATE_HUE}, 100%, 26%)`,
    illuminate_900: `hsl(${ILLUMINATE_HUE}, 100%, 18%)`,
    illuminate_950: `hsl(${ILLUMINATE_HUE}, 100%, 10%)`,
    illuminate_975: `hsl(${ILLUMINATE_HUE}, 100%, 7%)`,
    illuminate_1000: `hsl(${ILLUMINATE_HUE}, 100%, 4%)`,

    // lantica #853998
    lantica_0: `hsl(${LANTICA_HUE}, 100%, 100%)`,
    lantica_25: `hsl(${LANTICA_HUE}, 100%, 97%)`,
    lantica_50: `hsl(${LANTICA_HUE}, 100%, 95%)`,
    lantica_100: `hsl(${LANTICA_HUE}, 100%, 90%)`,
    lantica_200: `hsl(${LANTICA_HUE}, 100%, 80%)`,
    lantica_300: `hsl(${LANTICA_HUE}, 100%, 70%)`,
    lantica_400: `hsl(${LANTICA_HUE}, 100%, 60%)`,
    lantica_500: `hsl(${LANTICA_HUE}, 100%, 50%)`,
    lantica_600: `hsl(${LANTICA_HUE}, 100%, 42%)`,
    lantica_700: `hsl(${LANTICA_HUE}, 100%, 34%)`,
    lantica_800: `hsl(${LANTICA_HUE}, 100%, 26%)`,
    lantica_900: `hsl(${LANTICA_HUE}, 100%, 18%)`,
    lantica_950: `hsl(${LANTICA_HUE}, 100%, 10%)`,
    lantica_975: `hsl(${LANTICA_HUE}, 100%, 7%)`,
    lantica_1000: `hsl(${LANTICA_HUE}, 100%, 4%)`,

    // hokago #fa8333
    hokago_0: `hsl(${HOKAGO_HUE}, 100%, 100%)`,
    hokago_25: `hsl(${HOKAGO_HUE}, 100%, 97%)`,
    hokago_50: `hsl(${HOKAGO_HUE}, 100%, 95%)`,
    hokago_100: `hsl(${HOKAGO_HUE}, 100%, 90%)`,
    hokago_200: `hsl(${HOKAGO_HUE}, 100%, 80%)`,
    hokago_300: `hsl(${HOKAGO_HUE}, 100%, 70%)`,
    hokago_400: `hsl(${HOKAGO_HUE}, 100%, 60%)`,
    hokago_500: `hsl(${HOKAGO_HUE}, 100%, 50%)`,
    hokago_600: `hsl(${HOKAGO_HUE}, 100%, 42%)`,
    hokago_700: `hsl(${HOKAGO_HUE}, 100%, 34%)`,
    hokago_800: `hsl(${HOKAGO_HUE}, 100%, 26%)`,
    hokago_900: `hsl(${HOKAGO_HUE}, 100%, 18%)`,
    hokago_950: `hsl(${HOKAGO_HUE}, 100%, 10%)`,
    hokago_975: `hsl(${HOKAGO_HUE}, 100%, 7%)`,
    hokago_1000: `hsl(${HOKAGO_HUE}, 100%, 4%)`,

    // alstroemeria #ff699e
    alstroemeria_0: `hsl(${ALSTROEMERIA_HUE}, 100%, 100%)`,
    alstroemeria_25: `hsl(${ALSTROEMERIA_HUE}, 100%, 97%)`,
    alstroemeria_50: `hsl(${ALSTROEMERIA_HUE}, 100%, 95%)`,
    alstroemeria_100: `hsl(${ALSTROEMERIA_HUE}, 100%, 90%)`,
    alstroemeria_200: `hsl(${ALSTROEMERIA_HUE}, 100%, 80%)`,
    alstroemeria_300: `hsl(${ALSTROEMERIA_HUE}, 100%, 70%)`,
    alstroemeria_400: `hsl(${ALSTROEMERIA_HUE}, 100%, 60%)`,
    alstroemeria_500: `hsl(${ALSTROEMERIA_HUE}, 100%, 50%)`,
    alstroemeria_600: `hsl(${ALSTROEMERIA_HUE}, 100%, 42%)`,
    alstroemeria_700: `hsl(${ALSTROEMERIA_HUE}, 100%, 34%)`,
    alstroemeria_800: `hsl(${ALSTROEMERIA_HUE}, 100%, 26%)`,
    alstroemeria_900: `hsl(${ALSTROEMERIA_HUE}, 100%, 18%)`,
    alstroemeria_950: `hsl(${ALSTROEMERIA_HUE}, 100%, 10%)`,
    alstroemeria_975: `hsl(${ALSTROEMERIA_HUE}, 100%, 7%)`,
    alstroemeria_1000: `hsl(${ALSTROEMERIA_HUE}, 100%, 4%)`,

    // straylight #af011c
    straylight_0: `hsl(${STRAYLIGHT_HUE}, 100%, 100%)`,
    straylight_25: `hsl(${STRAYLIGHT_HUE}, 100%, 97%)`,
    straylight_50: `hsl(${STRAYLIGHT_HUE}, 100%, 95%)`,
    straylight_100: `hsl(${STRAYLIGHT_HUE}, 100%, 90%)`,
    straylight_200: `hsl(${STRAYLIGHT_HUE}, 100%, 80%)`,
    straylight_300: `hsl(${STRAYLIGHT_HUE}, 100%, 70%)`,
    straylight_400: `hsl(${STRAYLIGHT_HUE}, 100%, 60%)`,
    straylight_500: `hsl(${STRAYLIGHT_HUE}, 100%, 50%)`,
    straylight_600: `hsl(${STRAYLIGHT_HUE}, 100%, 42%)`,
    straylight_700: `hsl(${STRAYLIGHT_HUE}, 100%, 34%)`,
    straylight_800: `hsl(${STRAYLIGHT_HUE}, 100%, 26%)`,
    straylight_900: `hsl(${STRAYLIGHT_HUE}, 100%, 18%)`,
    straylight_950: `hsl(${STRAYLIGHT_HUE}, 100%, 10%)`,
    straylight_975: `hsl(${STRAYLIGHT_HUE}, 100%, 7%)`,
    straylight_1000: `hsl(${STRAYLIGHT_HUE}, 100%, 4%)`,

    // noctchill #384d98
    noctchill_0: `hsl(${NOCTCHILL_HUE}, 100%, 100%)`,
    noctchill_25: `hsl(${NOCTCHILL_HUE}, 100%, 97%)`,
    noctchill_50: `hsl(${NOCTCHILL_HUE}, 100%, 95%)`,
    noctchill_100: `hsl(${NOCTCHILL_HUE}, 100%, 90%)`,
    noctchill_200: `hsl(${NOCTCHILL_HUE}, 100%, 80%)`,
    noctchill_300: `hsl(${NOCTCHILL_HUE}, 100%, 70%)`,
    noctchill_400: `hsl(${NOCTCHILL_HUE}, 100%, 60%)`,
    noctchill_500: `hsl(${NOCTCHILL_HUE}, 100%, 50%)`,
    noctchill_600: `hsl(${NOCTCHILL_HUE}, 100%, 42%)`,
    noctchill_700: `hsl(${NOCTCHILL_HUE}, 100%, 34%)`,
    noctchill_800: `hsl(${NOCTCHILL_HUE}, 100%, 26%)`,
    noctchill_900: `hsl(${NOCTCHILL_HUE}, 100%, 18%)`,
    noctchill_950: `hsl(${NOCTCHILL_HUE}, 100%, 10%)`,
    noctchill_975: `hsl(${NOCTCHILL_HUE}, 100%, 7%)`,
    noctchill_1000: `hsl(${NOCTCHILL_HUE}, 100%, 4%)`,

    // shhis #008e74
    shhis_0: `hsl(${SHHIS_HUE}, 100%, 100%)`,
    shhis_25: `hsl(${SHHIS_HUE}, 100%, 97%)`,
    shhis_50: `hsl(${SHHIS_HUE}, 100%, 95%)`,
    shhis_100: `hsl(${SHHIS_HUE}, 100%, 90%)`,
    shhis_200: `hsl(${SHHIS_HUE}, 100%, 80%)`,
    shhis_300: `hsl(${SHHIS_HUE}, 100%, 70%)`,
    shhis_400: `hsl(${SHHIS_HUE}, 100%, 60%)`,
    shhis_500: `hsl(${SHHIS_HUE}, 100%, 50%)`,
    shhis_600: `hsl(${SHHIS_HUE}, 100%, 42%)`,
    shhis_700: `hsl(${SHHIS_HUE}, 100%, 34%)`,
    shhis_800: `hsl(${SHHIS_HUE}, 100%, 26%)`,
    shhis_900: `hsl(${SHHIS_HUE}, 100%, 18%)`,
    shhis_950: `hsl(${SHHIS_HUE}, 100%, 10%)`,
    shhis_975: `hsl(${SHHIS_HUE}, 100%, 7%)`,
    shhis_1000: `hsl(${SHHIS_HUE}, 100%, 4%)`,

    // cometik #333333
    cometik_0: `hsl(${COMETIK_HUE}, 0%, 100%)`,
    cometik_25: `hsl(${COMETIK_HUE}, 0%, 97%)`,
    cometik_50: `hsl(${COMETIK_HUE}, 0%, 95%)`,
    cometik_100: `hsl(${COMETIK_HUE}, 0%, 90%)`,
    cometik_200: `hsl(${COMETIK_HUE}, 0%, 80%)`,
    cometik_300: `hsl(${COMETIK_HUE}, 0%, 70%)`,
    cometik_400: `hsl(${COMETIK_HUE}, 0%, 60%)`,
    cometik_500: `hsl(${COMETIK_HUE}, 0%, 50%)`,
    cometik_600: `hsl(${COMETIK_HUE}, 0%, 42%)`,
    cometik_700: `hsl(${COMETIK_HUE}, 0%, 34%)`,
    cometik_800: `hsl(${COMETIK_HUE}, 0%, 26%)`,
    cometik_900: `hsl(${COMETIK_HUE}, 0%, 18%)`,
    cometik_950: `hsl(${COMETIK_HUE}, 0%, 10%)`,
    cometik_975: `hsl(${COMETIK_HUE}, 0%, 7%)`,
    cometik_1000: `hsl(${COMETIK_HUE}, 0%, 4%)`,
  } as const

  const lightPalette = {
    white: color.gray_0,
    black: color.gray_1000,

    contrast_25: color.gray_25,
    contrast_50: color.gray_50,
    contrast_100: color.gray_100,
    contrast_200: color.gray_200,
    contrast_300: color.gray_300,
    contrast_400: color.gray_400,
    contrast_500: color.gray_500,
    contrast_600: color.gray_600,
    contrast_700: color.gray_700,
    contrast_800: color.gray_800,
    contrast_900: color.gray_900,
    contrast_950: color.gray_950,
    contrast_975: color.gray_975,

    primary_25: color.primary_25,
    primary_50: color.primary_50,
    primary_100: color.primary_100,
    primary_200: color.primary_200,
    primary_300: color.primary_300,
    primary_400: color.primary_400,
    primary_500: color.primary_500,
    primary_600: color.primary_600,
    primary_700: color.primary_700,
    primary_800: color.primary_800,
    primary_900: color.primary_900,
    primary_950: color.primary_950,
    primary_975: color.primary_975,

    positive_25: color.green_25,
    positive_50: color.green_50,
    positive_100: color.green_100,
    positive_200: color.green_200,
    positive_300: color.green_300,
    positive_400: color.green_400,
    positive_500: color.green_500,
    positive_600: color.green_600,
    positive_700: color.green_700,
    positive_800: color.green_800,
    positive_900: color.green_900,
    positive_950: color.green_950,
    positive_975: color.green_975,

    negative_25: color.red_25,
    negative_50: color.red_50,
    negative_100: color.red_100,
    negative_200: color.red_200,
    negative_300: color.red_300,
    negative_400: color.red_400,
    negative_500: color.red_500,
    negative_600: color.red_600,
    negative_700: color.red_700,
    negative_800: color.red_800,
    negative_900: color.red_900,
    negative_950: color.red_950,
    negative_975: color.red_975,
  } as const

  const darkPalette: Palette = {
    white: color.gray_0,
    black: color.trueBlack,

    contrast_25: color.gray_975,
    contrast_50: color.gray_950,
    contrast_100: color.gray_900,
    contrast_200: color.gray_800,
    contrast_300: color.gray_700,
    contrast_400: color.gray_600,
    contrast_500: color.gray_500,
    contrast_600: color.gray_400,
    contrast_700: color.gray_300,
    contrast_800: color.gray_200,
    contrast_900: color.gray_100,
    contrast_950: color.gray_50,
    contrast_975: color.gray_25,

    primary_25: color.primary_975,
    primary_50: color.primary_950,
    primary_100: color.primary_900,
    primary_200: color.primary_800,
    primary_300: color.primary_700,
    primary_400: color.primary_600,
    primary_500: color.primary_500,
    primary_600: color.primary_400,
    primary_700: color.primary_300,
    primary_800: color.primary_200,
    primary_900: color.primary_100,
    primary_950: color.primary_50,
    primary_975: color.primary_25,

    positive_25: color.green_975,
    positive_50: color.green_950,
    positive_100: color.green_900,
    positive_200: color.green_800,
    positive_300: color.green_700,
    positive_400: color.green_600,
    positive_500: color.green_500,
    positive_600: color.green_400,
    positive_700: color.green_300,
    positive_800: color.green_200,
    positive_900: color.green_100,
    positive_950: color.green_50,
    positive_975: color.green_25,

    negative_25: color.red_975,
    negative_50: color.red_950,
    negative_100: color.red_900,
    negative_200: color.red_800,
    negative_300: color.red_700,
    negative_400: color.red_600,
    negative_500: color.red_500,
    negative_600: color.red_400,
    negative_700: color.red_300,
    negative_800: color.red_200,
    negative_900: color.red_100,
    negative_950: color.red_50,
    negative_975: color.red_25,
  } as const

  const dimPalette: Palette = {
    ...darkPalette,
    black: `hsl(${hues.primary}, 28%, ${dimScale[0]}%)`,

    contrast_25: `hsl(${hues.primary}, 28%, ${dimScale[1]}%)`,
    contrast_50: `hsl(${hues.primary}, 28%, ${dimScale[2]}%)`,
    contrast_100: `hsl(${hues.primary}, 28%, ${dimScale[3]}%)`,
    contrast_200: `hsl(${hues.primary}, 28%, ${dimScale[4]}%)`,
    contrast_300: `hsl(${hues.primary}, 24%, ${dimScale[5]}%)`,
    contrast_400: `hsl(${hues.primary}, 24%, ${dimScale[6]}%)`,
    contrast_500: `hsl(${hues.primary}, 20%, ${dimScale[7]}%)`,
    contrast_600: `hsl(${hues.primary}, 20%, ${dimScale[8]}%)`,
    contrast_700: `hsl(${hues.primary}, 20%, ${dimScale[9]}%)`,
    contrast_800: `hsl(${hues.primary}, 20%, ${dimScale[10]}%)`,
    contrast_900: `hsl(${hues.primary}, 20%, ${dimScale[11]}%)`,
    contrast_950: `hsl(${hues.primary}, 20%, ${dimScale[12]}%)`,
    contrast_975: `hsl(${hues.primary}, 20%, ${dimScale[13]}%)`,

    primary_25: `hsl(${hues.primary}, 50%, ${dimScale[1]}%)`,
    primary_50: `hsl(${hues.primary}, 60%, ${dimScale[2]}%)`,
    primary_100: `hsl(${hues.primary}, 70%, ${dimScale[3]}%)`,
    primary_200: `hsl(${hues.primary}, 82%, ${dimScale[4]}%)`,
    primary_300: `hsl(${hues.primary}, 90%, ${dimScale[5]}%)`,
    primary_400: `hsl(${hues.primary}, 95%, ${dimScale[6]}%)`,
    primary_500: `hsl(${hues.primary}, 99%, ${dimScale[7]}%)`,
    primary_600: `hsl(${hues.primary}, 99%, ${dimScale[8]}%)`,
    primary_700: `hsl(${hues.primary}, 99%, ${dimScale[9]}%)`,
    primary_800: `hsl(${hues.primary}, 99%, ${dimScale[10]}%)`,
    primary_900: `hsl(${hues.primary}, 99%, ${dimScale[11]}%)`,
    primary_950: `hsl(${hues.primary}, 99%, ${dimScale[12]}%)`,
    primary_975: `hsl(${hues.primary}, 99%, ${dimScale[13]}%)`,

    positive_25: `hsl(${hues.positive}, 50%, ${dimScale[1]}%)`,
    positive_50: `hsl(${hues.positive}, 60%, ${dimScale[2]}%)`,
    positive_100: `hsl(${hues.positive}, 70%, ${dimScale[3]}%)`,
    positive_200: `hsl(${hues.positive}, 82%, ${dimScale[4]}%)`,
    positive_300: `hsl(${hues.positive}, 82%, ${dimScale[5]}%)`,
    positive_400: `hsl(${hues.positive}, 82%, ${dimScale[6]}%)`,
    positive_500: `hsl(${hues.positive}, 82%, ${dimScale[7]}%)`,
    positive_600: `hsl(${hues.positive}, 82%, ${dimScale[8]}%)`,
    positive_700: `hsl(${hues.positive}, 82%, ${dimScale[9]}%)`,
    positive_800: `hsl(${hues.positive}, 82%, ${dimScale[10]}%)`,
    positive_900: `hsl(${hues.positive}, 82%, ${dimScale[11]}%)`,
    positive_950: `hsl(${hues.positive}, 82%, ${dimScale[12]}%)`,
    positive_975: `hsl(${hues.positive}, 82%, ${dimScale[13]}%)`,

    negative_25: `hsl(${hues.negative}, 70%, ${dimScale[1]}%)`,
    negative_50: `hsl(${hues.negative}, 80%, ${dimScale[2]}%)`,
    negative_100: `hsl(${hues.negative}, 84%, ${dimScale[3]}%)`,
    negative_200: `hsl(${hues.negative}, 88%, ${dimScale[4]}%)`,
    negative_300: `hsl(${hues.negative}, 91%, ${dimScale[5]}%)`,
    negative_400: `hsl(${hues.negative}, 91%, ${dimScale[6]}%)`,
    negative_500: `hsl(${hues.negative}, 91%, ${dimScale[7]}%)`,
    negative_600: `hsl(${hues.negative}, 91%, ${dimScale[8]}%)`,
    negative_700: `hsl(${hues.negative}, 91%, ${dimScale[9]}%)`,
    negative_800: `hsl(${hues.negative}, 91%, ${dimScale[10]}%)`,
    negative_900: `hsl(${hues.negative}, 91%, ${dimScale[11]}%)`,
    negative_950: `hsl(${hues.negative}, 91%, ${dimScale[12]}%)`,
    negative_975: `hsl(${hues.negative}, 91%, ${dimScale[13]}%)`,
  } as const

  const shinyPalette = {
    ...lightPalette,
    white: color.shiny_25,
    black: color.shiny_1000,

    contrast_25: color.shiny_25,
    contrast_50: color.shiny_50,
    contrast_100: color.shiny_100,
    contrast_200: color.shiny_200,
    contrast_300: color.shiny_300,
    contrast_400: color.shiny_400,
    contrast_500: color.shiny_500,
    contrast_600: color.shiny_600,
    contrast_700: color.shiny_700,
    contrast_800: color.shiny_800,
    contrast_900: color.shiny_900,
    contrast_950: color.shiny_950,
    contrast_975: color.shiny_975,
  } as const

  const illuminatePalette = {
    ...shinyPalette,
    white: color.illuminate_25,
    black: color.illuminate_1000,

    contrast_25: color.illuminate_25,
    contrast_50: color.illuminate_50,
    contrast_100: color.illuminate_100,
    contrast_200: color.illuminate_200,
    contrast_300: color.illuminate_300,
    contrast_400: color.illuminate_400,
    contrast_500: color.illuminate_500,
    contrast_600: color.illuminate_600,
    contrast_700: color.illuminate_700,
    contrast_800: color.illuminate_800,
    contrast_900: color.illuminate_900,
    contrast_950: color.illuminate_950,
    contrast_975: color.illuminate_975,
  } as const

  const lanticaPalette = {
    ...shinyPalette,
    white: color.lantica_25,
    black: color.lantica_1000,

    contrast_25: color.lantica_25,
    contrast_50: color.lantica_50,
    contrast_100: color.lantica_100,
    contrast_200: color.lantica_200,
    contrast_300: color.lantica_300,
    contrast_400: color.lantica_400,
    contrast_500: color.lantica_500,
    contrast_600: color.lantica_600,
    contrast_700: color.lantica_700,
    contrast_800: color.lantica_800,
    contrast_900: color.lantica_900,
    contrast_950: color.lantica_950,
    contrast_975: color.lantica_975,
  } as const

  const hokagoPalette = {
    ...shinyPalette,
    white: color.hokago_25,
    black: color.hokago_1000,

    contrast_25: color.hokago_25,
    contrast_50: color.hokago_50,
    contrast_100: color.hokago_100,
    contrast_200: color.hokago_200,
    contrast_300: color.hokago_300,
    contrast_400: color.hokago_400,
    contrast_500: color.hokago_500,
    contrast_600: color.hokago_600,
    contrast_700: color.hokago_700,
    contrast_800: color.hokago_800,
    contrast_900: color.hokago_900,
    contrast_950: color.hokago_950,
    contrast_975: color.hokago_975,
  } as const

  const alstroemeriaPalette = {
    ...shinyPalette,
    white: color.alstroemeria_25,
    black: color.alstroemeria_1000,

    contrast_25: color.alstroemeria_25,
    contrast_50: color.alstroemeria_50,
    contrast_100: color.alstroemeria_100,
    contrast_200: color.alstroemeria_200,
    contrast_300: color.alstroemeria_300,
    contrast_400: color.alstroemeria_400,
    contrast_500: color.alstroemeria_500,
    contrast_600: color.alstroemeria_600,
    contrast_700: color.alstroemeria_700,
    contrast_800: color.alstroemeria_800,
    contrast_900: color.alstroemeria_900,
    contrast_950: color.alstroemeria_950,
    contrast_975: color.alstroemeria_975,
  } as const

  const straylightPalette = {
    ...shinyPalette,
    white: color.straylight_25,
    black: color.straylight_1000,

    contrast_25: color.straylight_25,
    contrast_50: color.straylight_50,
    contrast_100: color.straylight_100,
    contrast_200: color.straylight_200,
    contrast_300: color.straylight_300,
    contrast_400: color.straylight_400,
    contrast_500: color.straylight_500,
    contrast_600: color.straylight_600,
    contrast_700: color.straylight_700,
    contrast_800: color.straylight_800,
    contrast_900: color.straylight_900,
    contrast_950: color.straylight_950,
    contrast_975: color.straylight_975,
  } as const

  const noctchillPalette = {
    ...shinyPalette,
    white: color.noctchill_25,
    black: color.noctchill_1000,

    contrast_25: color.noctchill_25,
    contrast_50: color.noctchill_50,
    contrast_100: color.noctchill_100,
    contrast_200: color.noctchill_200,
    contrast_300: color.noctchill_300,
    contrast_400: color.noctchill_400,
    contrast_500: color.noctchill_500,
    contrast_600: color.noctchill_600,
    contrast_700: color.noctchill_700,
    contrast_800: color.noctchill_800,
    contrast_900: color.noctchill_900,
    contrast_950: color.noctchill_950,
    contrast_975: color.noctchill_975,
  } as const

  const shhisPalette = {
    ...shinyPalette,
    white: color.shhis_25,
    black: color.shhis_1000,

    contrast_25: color.shhis_25,
    contrast_50: color.shhis_50,
    contrast_100: color.shhis_100,
    contrast_200: color.shhis_200,
    contrast_300: color.shhis_300,
    contrast_400: color.shhis_400,
    contrast_500: color.shhis_500,
    contrast_600: color.shhis_600,
    contrast_700: color.shhis_700,
    contrast_800: color.shhis_800,
    contrast_900: color.shhis_900,
    contrast_950: color.shhis_950,
    contrast_975: color.shhis_975,
  } as const

  const cometikPalette = {
    ...shinyPalette,
    white: color.cometik_25,
    black: color.cometik_1000,

    contrast_25: color.cometik_25,
    contrast_50: color.cometik_50,
    contrast_100: color.cometik_100,
    contrast_200: color.cometik_200,
    contrast_300: color.cometik_300,
    contrast_400: color.cometik_400,
    contrast_500: color.cometik_500,
    contrast_600: color.cometik_600,
    contrast_700: color.cometik_700,
    contrast_800: color.cometik_800,
    contrast_900: color.cometik_900,
    contrast_950: color.cometik_950,
    contrast_975: color.cometik_975,
  } as const

  const light: Theme = {
    name: 'light',
    palette: lightPalette,
    atoms: {
      text: {
        color: lightPalette.black,
      },
      text_contrast_low: {
        color: lightPalette.contrast_400,
      },
      text_contrast_medium: {
        color: lightPalette.contrast_700,
      },
      text_contrast_high: {
        color: lightPalette.contrast_900,
      },
      text_inverted: {
        color: lightPalette.white,
      },
      bg: {
        backgroundColor: lightPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: lightPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: lightPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: lightPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: lightPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: lightPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: lightPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: lightPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: lightPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: lightPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: lightPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: lightPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: lightPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: lightPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: lightPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: lightPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: lightPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: lightPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: lightPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: lightPalette.black,
      },
    },
  }

  const dark: Theme = {
    name: 'dark',
    palette: darkPalette,
    atoms: {
      text: {
        color: darkPalette.white,
      },
      text_contrast_low: {
        color: darkPalette.contrast_400,
      },
      text_contrast_medium: {
        color: darkPalette.contrast_600,
      },
      text_contrast_high: {
        color: darkPalette.contrast_900,
      },
      text_inverted: {
        color: darkPalette.black,
      },
      bg: {
        backgroundColor: darkPalette.black,
      },
      bg_contrast_25: {
        backgroundColor: darkPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: darkPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: darkPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: darkPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: darkPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: darkPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: darkPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: darkPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: darkPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: darkPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: darkPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: darkPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: darkPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: darkPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: darkPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: darkPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowOpacity: 0.7,
        shadowColor: color.trueBlack,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowOpacity: 0.7,
        shadowColor: color.trueBlack,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowOpacity: 0.7,
        shadowColor: color.trueBlack,
      },
    },
  }

  const dim: Theme = {
    ...dark,
    name: 'dim',
    palette: dimPalette,
    atoms: {
      ...dark.atoms,
      text: {
        color: dimPalette.white,
      },
      text_contrast_low: {
        color: dimPalette.contrast_400,
      },
      text_contrast_medium: {
        color: dimPalette.contrast_700,
      },
      text_contrast_high: {
        color: dimPalette.contrast_900,
      },
      text_inverted: {
        color: dimPalette.black,
      },
      bg: {
        backgroundColor: dimPalette.black,
      },
      bg_contrast_25: {
        backgroundColor: dimPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: dimPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: dimPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: dimPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: dimPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: dimPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: dimPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: dimPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: dimPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: dimPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: dimPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: dimPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: dimPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: dimPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: dimPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: dimPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowOpacity: 0.7,
        shadowColor: `hsl(${hues.primary}, 28%, 6%)`,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowOpacity: 0.7,
        shadowColor: `hsl(${hues.primary}, 28%, 6%)`,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowOpacity: 0.7,
        shadowColor: `hsl(${hues.primary}, 28%, 6%)`,
      },
    },
  }

  const shiny: Theme = {
    ...light,
    name: 'shiny' as ThemeName,
    palette: shinyPalette,
    atoms: {
      ...light.atoms,
      text: {
        color: shinyPalette.black,
      },
      text_contrast_low: {
        color: shinyPalette.contrast_400,
      },
      text_contrast_medium: {
        color: shinyPalette.contrast_700,
      },
      text_contrast_high: {
        color: shinyPalette.contrast_900,
      },
      text_inverted: {
        color: shinyPalette.white,
      },
      bg: {
        backgroundColor: shinyPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: shinyPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: shinyPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: shinyPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: shinyPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: shinyPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: shinyPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: shinyPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: shinyPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: shinyPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: shinyPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: shinyPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: shinyPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: shinyPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: shinyPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: shinyPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: shinyPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: shinyPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: shinyPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: shinyPalette.black,
      },
    },
  }

  const illuminate: Theme = {
    ...shiny,
    name: 'illuminate' as ThemeName,
    palette: illuminatePalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: illuminatePalette.black,
      },
      text_contrast_low: {
        color: illuminatePalette.contrast_400,
      },
      text_contrast_medium: {
        color: illuminatePalette.contrast_700,
      },
      text_contrast_high: {
        color: illuminatePalette.contrast_900,
      },
      text_inverted: {
        color: illuminatePalette.white,
      },
      bg: {
        backgroundColor: illuminatePalette.white,
      },
      bg_contrast_25: {
        backgroundColor: illuminatePalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: illuminatePalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: illuminatePalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: illuminatePalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: illuminatePalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: illuminatePalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: illuminatePalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: illuminatePalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: illuminatePalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: illuminatePalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: illuminatePalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: illuminatePalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: illuminatePalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: illuminatePalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: illuminatePalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: illuminatePalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: illuminatePalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: illuminatePalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: illuminatePalette.black,
      },
    },
  }

  const lantica: Theme = {
    ...shiny,
    name: 'lantica' as ThemeName,
    palette: lanticaPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: lanticaPalette.black,
      },
      text_contrast_low: {
        color: lanticaPalette.contrast_400,
      },
      text_contrast_medium: {
        color: lanticaPalette.contrast_700,
      },
      text_contrast_high: {
        color: lanticaPalette.contrast_900,
      },
      text_inverted: {
        color: lanticaPalette.white,
      },
      bg: {
        backgroundColor: lanticaPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: lanticaPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: lanticaPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: lanticaPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: lanticaPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: lanticaPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: lanticaPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: lanticaPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: lanticaPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: lanticaPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: lanticaPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: lanticaPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: lanticaPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: lanticaPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: lanticaPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: lanticaPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: lanticaPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: lanticaPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: lanticaPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: lanticaPalette.black,
      },
    },
  }

  const hokago: Theme = {
    ...shiny,
    name: 'hokago' as ThemeName,
    palette: hokagoPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: hokagoPalette.black,
      },
      text_contrast_low: {
        color: hokagoPalette.contrast_400,
      },
      text_contrast_medium: {
        color: hokagoPalette.contrast_700,
      },
      text_contrast_high: {
        color: hokagoPalette.contrast_900,
      },
      text_inverted: {
        color: hokagoPalette.white,
      },
      bg: {
        backgroundColor: hokagoPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: hokagoPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: hokagoPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: hokagoPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: hokagoPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: hokagoPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: hokagoPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: hokagoPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: hokagoPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: hokagoPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: hokagoPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: hokagoPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: hokagoPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: hokagoPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: hokagoPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: hokagoPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: hokagoPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: hokagoPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: hokagoPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: hokagoPalette.black,
      },
    },
  }

  const alstroemeria: Theme = {
    ...shiny,
    name: 'alstroemeria' as ThemeName,
    palette: alstroemeriaPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: alstroemeriaPalette.black,
      },
      text_contrast_low: {
        color: alstroemeriaPalette.contrast_400,
      },
      text_contrast_medium: {
        color: alstroemeriaPalette.contrast_700,
      },
      text_contrast_high: {
        color: alstroemeriaPalette.contrast_900,
      },
      text_inverted: {
        color: alstroemeriaPalette.white,
      },
      bg: {
        backgroundColor: alstroemeriaPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: alstroemeriaPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: alstroemeriaPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: alstroemeriaPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: alstroemeriaPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: alstroemeriaPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: alstroemeriaPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: alstroemeriaPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: alstroemeriaPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: alstroemeriaPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: alstroemeriaPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: alstroemeriaPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: alstroemeriaPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: alstroemeriaPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: alstroemeriaPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: alstroemeriaPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: alstroemeriaPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: alstroemeriaPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: alstroemeriaPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: alstroemeriaPalette.black,
      },
    },
  }

  const straylight: Theme = {
    ...shiny,
    name: 'straylight' as ThemeName,
    palette: straylightPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: straylightPalette.black,
      },
      text_contrast_low: {
        color: straylightPalette.contrast_400,
      },
      text_contrast_medium: {
        color: straylightPalette.contrast_700,
      },
      text_contrast_high: {
        color: straylightPalette.contrast_900,
      },
      text_inverted: {
        color: straylightPalette.white,
      },
      bg: {
        backgroundColor: straylightPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: straylightPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: straylightPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: straylightPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: straylightPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: straylightPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: straylightPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: straylightPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: straylightPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: straylightPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: straylightPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: straylightPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: straylightPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: straylightPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: straylightPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: straylightPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: straylightPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: straylightPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: straylightPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: straylightPalette.black,
      },
    },
  }

  const noctchill: Theme = {
    ...shiny,
    name: 'noctchill' as ThemeName,
    palette: noctchillPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: noctchillPalette.black,
      },
      text_contrast_low: {
        color: noctchillPalette.contrast_400,
      },
      text_contrast_medium: {
        color: noctchillPalette.contrast_700,
      },
      text_contrast_high: {
        color: noctchillPalette.contrast_900,
      },
      text_inverted: {
        color: noctchillPalette.white,
      },
      bg: {
        backgroundColor: noctchillPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: noctchillPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: noctchillPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: noctchillPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: noctchillPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: noctchillPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: noctchillPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: noctchillPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: noctchillPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: noctchillPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: noctchillPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: noctchillPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: noctchillPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: noctchillPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: noctchillPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: noctchillPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: noctchillPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: noctchillPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: noctchillPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: noctchillPalette.black,
      },
    },
  }

  const shhis: Theme = {
    ...shiny,
    name: 'shhis' as ThemeName,
    palette: shhisPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: shhisPalette.black,
      },
      text_contrast_low: {
        color: shhisPalette.contrast_400,
      },
      text_contrast_medium: {
        color: shhisPalette.contrast_700,
      },
      text_contrast_high: {
        color: shhisPalette.contrast_900,
      },
      text_inverted: {
        color: shhisPalette.white,
      },
      bg: {
        backgroundColor: shhisPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: shhisPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: shhisPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: shhisPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: shhisPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: shhisPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: shhisPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: shhisPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: shhisPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: shhisPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: shhisPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: shhisPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: shhisPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: shhisPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: shhisPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: shhisPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: shhisPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: shhisPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: shhisPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: shhisPalette.black,
      },
    },
  }

  const cometik: Theme = {
    ...shiny,
    name: 'cometik' as ThemeName,
    palette: cometikPalette,
    atoms: {
      ...shiny.atoms,
      text: {
        color: cometikPalette.black,
      },
      text_contrast_low: {
        color: cometikPalette.contrast_400,
      },
      text_contrast_medium: {
        color: cometikPalette.contrast_700,
      },
      text_contrast_high: {
        color: cometikPalette.contrast_900,
      },
      text_inverted: {
        color: cometikPalette.white,
      },
      bg: {
        backgroundColor: cometikPalette.white,
      },
      bg_contrast_25: {
        backgroundColor: cometikPalette.contrast_25,
      },
      bg_contrast_50: {
        backgroundColor: cometikPalette.contrast_50,
      },
      bg_contrast_100: {
        backgroundColor: cometikPalette.contrast_100,
      },
      bg_contrast_200: {
        backgroundColor: cometikPalette.contrast_200,
      },
      bg_contrast_300: {
        backgroundColor: cometikPalette.contrast_300,
      },
      bg_contrast_400: {
        backgroundColor: cometikPalette.contrast_400,
      },
      bg_contrast_500: {
        backgroundColor: cometikPalette.contrast_500,
      },
      bg_contrast_600: {
        backgroundColor: cometikPalette.contrast_600,
      },
      bg_contrast_700: {
        backgroundColor: cometikPalette.contrast_700,
      },
      bg_contrast_800: {
        backgroundColor: cometikPalette.contrast_800,
      },
      bg_contrast_900: {
        backgroundColor: cometikPalette.contrast_900,
      },
      bg_contrast_950: {
        backgroundColor: cometikPalette.contrast_950,
      },
      bg_contrast_975: {
        backgroundColor: cometikPalette.contrast_975,
      },
      border_contrast_low: {
        borderColor: cometikPalette.contrast_100,
      },
      border_contrast_medium: {
        borderColor: cometikPalette.contrast_200,
      },
      border_contrast_high: {
        borderColor: cometikPalette.contrast_300,
      },
      shadow_sm: {
        ...atoms.shadow_sm,
        shadowColor: cometikPalette.black,
      },
      shadow_md: {
        ...atoms.shadow_md,
        shadowColor: cometikPalette.black,
      },
      shadow_lg: {
        ...atoms.shadow_lg,
        shadowColor: cometikPalette.black,
      },
    },
  }

  return {
    lightPalette,
    darkPalette,
    dimPalette,
    shinyPalette,
    illuminatePalette,
    lanticaPalette,
    hokagoPalette,
    alstroemeriaPalette,
    straylightPalette,
    noctchillPalette,
    shhisPalette,
    cometikPalette,
    light,
    dark,
    dim,
    shiny,
    illuminate,
    lantica,
    hokago,
    alstroemeria,
    straylight,
    noctchill,
    shhis,
    cometik,
  }
}
