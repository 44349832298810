import {ThemeName} from '#/alf/types'

export function select<T>(name: ThemeName, options: Record<ThemeName, T>) {
  switch (name) {
    case 'light':
      return options.light
    case 'dark':
      return options.dark || options.dim
    case 'dim':
      return options.dim || options.dark
    case 'shiny':
      return options.shiny
    case 'illuminate':
      return options.illuminate || options.shiny
    case 'lantica':
      return options.lantica || options.shiny
    case 'hokago':
      return options.hokago || options.shiny
    case 'alstroemeria':
      return options.alstroemeria || options.shiny
    case 'straylight':
      return options.straylight || options.shiny
    case 'noctchill':
      return options.noctchill || options.shiny
    case 'shhis':
      return options.shhis || options.shiny
    case 'cometik':
      return options.cometik || options.shiny
    default:
      throw new Error(`select(theme, options) received unknown theme ${name}`)
  }
}
